<script setup>
import { useForm } from '@inertiajs/vue3';

import PublicLayout from '@/Layouts/PublicLayout.vue';

// Componnets
import ContentGrid from '@/Components/ContentGrid.vue';

// Partials
import FilterButtons from '@/Components/FilterButtons.vue';
import MainTitle from '@/Components/MainTitle.vue';
import { inject } from 'vue';
import FaqCategoriesList from '../../Components/Faq/FaqCategoriesList.vue';
import Footer from './Partials/Footer.vue';

const route = inject('route');

const props = defineProps({
    query: Object,
    categories: Object,
    categoryOptions: Object,
    faqItems: Object,
});

const getData = (resetPage = true) => {
    form.get(route('faqs'), {});
};

const form = useForm({
    currentCategories: props.query.currentCategories ?? [],
});
</script>

<template>
    <PublicLayout title="FLEXABLE | FAQ's">
        <ContentGrid>
            <MainTitle :title="$t('FAQ')" />

            <FilterButtons
                :categoryOptions="categoryOptions"
                v-model:currentCategories="form.currentCategories"
                @update:current-categories="getData()"
            />

            <FaqCategoriesList :categories="categories" />
        </ContentGrid>
    </PublicLayout>
</template>
